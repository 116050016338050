import {SubmissionError} from 'redux-form';
import {get, patch, del, post} from 'core/configureRequest';
import {notifyError, notifySuccessWithTimeout} from 'features/notification/slice';
import {showModal, hideModal} from 'features/modal/slice';
import {getAccount} from 'features/accounts/actions';
import {getAccountIfNeeded} from 'features/accounts/actions';
import {selectIterableListings} from 'features/listings/selectors';
import {
    isEmpty,
    intersection,
    omit,
    omitRecursive,
    getListingName,
    getListingType,
    convertPhotosToBase64,
    getDetailedError,
    getFieldError,
    queryStringConverter,
} from 'utils/helpers';
import {getListingSubscriptions} from 'features/vip/actions';
import {EVENT_CATEGORIES, LISTING_TYPES_PAID} from 'core/constants';

const DEFAULT_LISTING_COUNT = 15;

export const requestListing = () => ({
    type: 'REQUEST_LISTING',
});

export const requestListings = () => ({
    type: 'REQUEST_LISTINGS',
});

export const receiveListingFailure = () => ({
    type: 'RECEIVE_LISTING_FAILURE',
});

export const receiveListingsFailure = () => ({
    type: 'RECEIVE_LISTINGS_FAILURE',
});

export const receiveListing = (listing) => ({
    type: 'RECEIVE_LISTING',
    listing,
});

export const receiveListings = (listings) => ({
    type: 'RECEIVE_LISTINGS',
    listings,
});

export const requestDeleteListing = () => ({
    type: 'REQUEST_DELETE_LISTING',
});

export const receiveDeleteListing = (listingId, accountId) => ({
    type: 'RECEIVE_DELETE_LISTING',
    listingId,
    accountId,
});

export const requestAccountListings = () => ({
    type: 'REQUEST_ACCOUNT_LISTINGS',
});

export const receiveSearchResults = (listings, pagination) => ({
    type: 'RECEIVE_LISTINGS_SEARCH',
    listings,
    pagination,
});

export const receiveAccountListings = (listings, accountId) => ({
    type: 'RECEIVE_ACCOUNT_LISTINGS',
    listings,
    accountId,
});

export const requestEditListingType = (from, to) => ({
    type: 'RECEIVE_EDIT_LISTING_TYPE',
    meta: {
        analytics: {
            category: EVENT_CATEGORIES.Listing,
            label: `${from} to ${to}`,
        },
    },
});

function getEditedField(values) {
    if (values.tags) {
        return Object.keys(values.tags).join();
    }

    return Object.keys(omit(values, 'listing_id')).join();
}

export const requestEditListing = (values) => ({
    type: 'REQUEST_EDIT_LISTING',
    meta: {
        analytics: {
            category: EVENT_CATEGORIES.Listing,
            label: getEditedField(values),
        },
    },
});

export const getListing = (listingId) => (dispatch, getState) => {
    dispatch(requestListings());

    return get(`/proxy/api/listing/${listingId}/`, {format: 'cr'})
        .then((data) => {
            dispatch(receiveListing(data.data.listing));

            return data;
        })
        .catch((e) => {
            dispatch(receiveListingFailure());
            dispatch(notifyError(`Could not fetch listing ${listingId}`));
            return Promise.reject(e);
        });
};

export const getListings = () => (dispatch, getState) => {
    dispatch(requestListings());

    return get('/proxy/api/listing/')
        .then((data) => {
            dispatch(receiveListings(data.data.listings));

            return data;
        })
        .catch((e) => {
            dispatch(receiveListingsFailure());
            return Promise.reject(e);
        });
};

export const getListingsIfNeeded = () => (dispatch, getState) => {
    const state = getState();
    const currentAccountId = state.accounts.currentAccountId;
    const account = state.accounts.entities[currentAccountId];

    // If account has not yet been fetched, fetch listings
    if (!account) return dispatch(getListings());

    if (account.response_format === 'formatDetails') {
        // Do fetch only if there are equal to or fewer listings loaded than listings in the current account
        const allListingIds = Object.keys(state.listings.entities);
        const currentAccountListingIds = account.listings;

        if (allListingIds.length > currentAccountListingIds.length) return;
    }

    return dispatch(getListings());
};

export const getAccountListings = (accountId) => (dispatch, getState) => {
    dispatch(requestAccountListings());

    return get(`/proxy/api/listing/?account_id=${accountId}`)
        .then((data) => {
            /**
             * RB Account returns 150 listings,
             * truncate these listings for state store
             */
            if (
                data &&
                data.data &&
                data.data.listings &&
                data.data.listings.length > 100
            ) {
                let rbListings = data.data.listings
                    .filter((listing) => listing.photos && listing.photos.length)
                    .slice(0, 9);

                if (rbListings.length < 9) {
                    const remaining = 9 - rbListings.length;
                    const listingsWithoutPhotos = data.data.listings.filter(
                        (listing) => !listing.photos.length,
                    );

                    rbListings = listingsWithoutPhotos
                        .slice(0, remaining)
                        .concat(rbListings);
                }

                return dispatch(receiveAccountListings(rbListings, accountId));
            }

            dispatch(receiveAccountListings(data.data.listings, accountId));

            return data;
        })
        .catch((e) => {
            dispatch(receiveListingsFailure());
            return Promise.reject(e);
        });
};

export const getAccountListingsIfNeeded = (accountId) => (dispatch, getState) => {
    const fetched = getState().listings.accountIdsFetched;
    if (fetched && fetched.includes(accountId)) {
        const accountListings = selectIterableListings(getState()).filter(
            (listing) => listing.account_id && listing.account_id === accountId,
        );

        return Promise.resolve(accountListings);
    }

    return dispatch(getAccountListings(accountId));
};

export const deleteListing = (listing) => (dispatch, getState) => {
    dispatch(requestDeleteListing());

    const promise = new Promise((resolve, reject) => {
        return dispatch(
            showModal({
                type: 'DELETE_ACCOUNT',
                props: {
                    type: 'Warning',
                    message: `Are you sure you want to delete ${listing.listing_name}?`,
                    loadingText: 'Deleting...',
                    confirmText: 'Delete',
                    handleClose: () => dispatch(hideModal()),
                    handleConfirm: () => {
                        return del(`/proxy/api/listing/${listing.listing_id}/`)
                            .then((data) => {
                                dispatch(hideModal());

                                dispatch(
                                    notifySuccessWithTimeout(
                                        `Listing ${listing.listing_name} successfully deleted!`,
                                    ),
                                );
                                dispatch(
                                    receiveDeleteListing(
                                        data.data.listing_id,
                                        listing.account_id,
                                    ),
                                );

                                return resolve(data);
                            })
                            .catch((e) => {
                                dispatch(notifyError('Could not delete listing'));
                                dispatch(hideModal());
                                return reject(e);
                            });
                    },
                },
            }),
        );
    });

    return promise;
};

/**
 * Pre-appends 'https://' to URLs if they don't already start with it
 * @param {Array} links - Array of link objects
 * @returns {Array} - Array with URLs updated
 */
const updateUrls = (links) => {
    return links.map((link) => {
        if (link && link.url) {
            if (link.url.includes('http:')) {
                link.url = link.url.replace('http:', 'https:');
            } else if (!link.url.startsWith('https://')) {
                link.url = 'https://' + link.url;
            }
        }

        return link;
    });
};

export const editListing = (values) => (dispatch, getState) => {
    dispatch(requestEditListing(values));

    let valuesToSubmit = {...values};

    /**
     * Checks to see if the URL starts with https://
     * if not, then pre-append https://
     */
    updateUrls(values.keyword_optimized_links || []);
    updateUrls(values.virtual_tour_links || []);
    updateUrls(values.social_media_links || []);

    /**
     * Listing "tags" exist as own db entry,
     * so if you change e.g., listing.tags.services
     * you must include all preexisting listing.tags values
     */
    const listing = getState().listings.entities[values.listing_id];
    if (values.tags) {
        valuesToSubmit.tags = Object.assign({}, listing.tags, valuesToSubmit.tags);
    }

    /**
     * values returned from API and stored in state with "listing_name"
     * but must be patched with "name"
     */
    if (values.listing_name) {
        valuesToSubmit = Object.assign({}, omit(valuesToSubmit, 'listing_name'), {
            name: valuesToSubmit.listing_name,
        });
    }

    /**
     * Phone numbers should have description: "Local Phone"
     * when patched
     */
    if (valuesToSubmit.phones && !isEmpty(valuesToSubmit.phones)) {
        valuesToSubmit.phones = [
            Object.assign({}, valuesToSubmit.phones[0], {description: 'Local Phone'}),
            ...valuesToSubmit.phones.slice(1),
        ];
    }

    return convertPhotosToBase64(valuesToSubmit)
        .then((values) => {
            const sanitized = omitRecursive(
                values,
                'listing_id',
                'created_at',
                'modified_at',
            );
            return patch(`/proxy/api/listing/${values.listing_id}/`, sanitized);
        })
        .catch((e) => {
            dispatch(notifyError(e.message));
            return Promise.reject(e);
        })
        .then((data) => {
            dispatch(receiveListing(data.data.listing));

            return data;
        })
        .catch((e) => {
            dispatch(receiveListingFailure());
            dispatch(notifyError(getFieldError(e)));
            return Promise.reject(e);
        });
};

export const editListingType = (listing, listingType) => (dispatch, getState) => {
    const currentListingType = getListingType(listing);

    dispatch(requestEditListingType(currentListingType, listingType));

    const fromPaid = LISTING_TYPES_PAID.includes(currentListingType) && (listingType === 'claimed' || listingType === 'free');
    const fromUnpaid = LISTING_TYPES_PAID.includes(listingType) && (currentListingType === 'claimed' || currentListingType === 'free');

    if (fromPaid) {
        return dispatch(
            showModal({
                type: 'CHANGE_LISTING_TYPE',
                props: {
                    listing: listing,
                    type: listingType,
                },
            }),
        );
    }

    if (fromUnpaid) {
        return dispatch(
            showModal({
                type: 'UPGRADE_LISTING_TYPE',
                props: {
                    listing: listing,
                    toType: listingType,
                },
            }),
        );
    }

    return dispatch(editListing({listing_id: listing.listing_id, type: listingType}))
        .then((data) => {
            dispatch(notifySuccessWithTimeout(listing.listing_name + ' was updated'));

            return data;
        })
        .catch((e) =>
            dispatch(notifyError(e && e.message ? e.message : 'Could not edit listing')),
        );
};

export const createListing = (values) => (dispatch, getState) => {
    return post('/proxy/api/listing/', values).then((data) => {
        dispatch(receiveListing(data.data.listing));

        return data;
    });
};

export const searchListings = (page) => (dispatch, getState) => {
    const state = getState();

    const formValues =
        state.form['search-listings'] && state.form['search-listings'].values;

    const query = queryStringConverter({
        page: page || 1,
        count: DEFAULT_LISTING_COUNT,
        cached: 0,
        ...formValues,
    });

    dispatch(requestListings());

    return get(
        `/proxy/api/listing/${
            formValues?.type ? 'tracking-numbers-to-fix/' : ''
        }${query}`,
    )
        .then((data) => {
            dispatch(
                receiveSearchResults(data.data.listings, omit(data.data, 'listings')),
            );

            return data;
        })
        .catch((e) => {
            dispatch(receiveListingsFailure());
            return Promise.reject(dispatch(notifyError('Could not fetch listings')));
        });
};

export const attachListing = (listing, account) => (dispatch, getState) => {
    const accountId = account.account_id;
    const accountName = account.organization_name;

    /**
     * Attach listing to specified account
     * or remove listing from specified account
     */
    const shouldAttach = accountId !== listing.account_id;
    const actionText = shouldAttach ? 'attach' : 'remove';
    const attachText = shouldAttach ? 'to' : 'from';
    const listingName = getListingName(listing);
    const message = `Are you sure you want to ${actionText} ${listingName} ${attachText} ${accountName}?`;

    /**
     * editListing action recursively omits null values
     * in this case patching "account_id" with null value
     * assigns the listing to the RB account
     */

    const promise = new Promise((resolve, reject) => {
        dispatch(
            showModal({
                type: 'ATTACH_LISTING',
                props: {
                    message,
                    handleConfirm: () => {
                        return patch(`/proxy/api/listing/${listing.listing_id}/`, {
                            account_id: shouldAttach ? account.account_id : null,
                        })
                            .then((data) => {
                                dispatch(receiveListing(data.data.listing));
                                dispatch(hideModal());
                                dispatch(
                                    notifySuccessWithTimeout(
                                        `${getListingName(listing)} successfully edited!`,
                                    ),
                                );

                                return resolve(data);
                            })
                            .catch((e) => {
                                dispatch(
                                    notifyError(
                                        (e && e.message) || 'Could not attach listing',
                                    ),
                                );
                                return reject(e);
                            });
                    },
                    handleClose: () => dispatch(hideModal()),
                    confirmText: shouldAttach ? 'Attach' : 'Remove',
                    type: 'Warning',
                    loadingText: 'Saving...',
                },
            }),
        );
    });

    return promise;
};

export const editPhoto = (photoIndex) => ({
    type: 'EDIT_LISTING_PHOTO',
    index: photoIndex,
});
